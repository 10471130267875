import { Link, useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import ArticleCard from "../components/articleCard";
import parse from "html-react-parser";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import BottomBanner from "../components/bottom-banner";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import SvgFanHp from "../components/svg/fan-svg-hp";
import { GatsbyImage } from "gatsby-plugin-image";
import HubspotForm from "react-hubspot-form";

const VolunteerFormPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "News",
				item: {
					url: `${siteUrl}/news`,
					id: `${siteUrl}/news`,
				},
			},
		],
	};

	return (
		<Layout>
			<div style={{ overflow: "hidden" }}>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>

				<GatsbySeo
					title="News | Eastbourne Carnival"
					description="News about Eastbourne Carnival"
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/news`,
						title: "News | Eastbourne Carnival",
						description: "News about Eastbourne Carnival",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>

				<section className="pt-5 pb-md-10  position-relative">
					<Container>
						<Row className="justify-content-center pb-lg-7 ">
							<Col lg={12}>
								<h1 className=" text-center display-4 pb-4 carnival-normal text-secondary">
									Volunteers – information
								</h1>
							</Col>
						</Row>
						<Row>
							<Col>
								<p>
									Support your local Carnival and get involved as a Volunteer!
								</p>
								<p>
									We need Marshals for Carnival to be able to happen. You will
									be fully trained and given PPE.
								</p>
								<p>
									We also need bucket collectors to collect contributions from
									the public. The money raised goes towards next years’ Carnival
									and is distributed to local charities on an even basis.
								</p>
								<p>
									If you’d like to register your interest in volunteering as a
									Marshal or Bucket Collector - please complete this form
									indicating which role you are keen to do:
								</p>
							</Col>
						</Row>
						<Row>
							<Col>
								{" "}
								<HubspotForm
									region="eu1"
									portalId="25477959"
									formId="215ac23d-0b2b-487f-9c91-58e46ff69310"
									onSubmit={() => console.log("Submit!")}
									onReady={(form) => console.log("Form ready!")}
									loading={<div>Loading...</div>}
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<BottomBanner
					mainBg="bg-secondary"
					position="top--20"
					content={
						<Row>
							<Col className="py-5 pb-md-0 mt-md-5 pt-md-10">
								<h2 className="text-white text-center  fs-1 shortstack">
									FOLLOW US ON SOCIAL MEDIA
								</h2>
								<div className="d-flex pt-4 justify-content-center align-items-center">
									<a
										rel="noreferrer"
										target="_blank"
										href="https://www.facebook.com/eastbournecarnival"
									>
										<FaFacebookF className="text-white display-2 link-white" />
									</a>
									<a
										rel="noreferrer"
										target="_blank"
										href="https://twitter.com/EBCarnival"
									>
										<FaTwitter className="text-white display-2 ms-5 link-white" />
									</a>
									<a
										rel="noreferrer"
										target="_blank"
										href="https://www.instagram.com/eastbournecarnival/"
									>
										<FaInstagram className="text-white display-2 ms-5 link-white" />
									</a>
									<a
										rel="noreferrer"
										target="_blank"
										href="https://www.youtube.com/@eastbournecarnival990"
									>
										<FaYoutube className="text-white display-2 ms-5 link-white" />
									</a>
								</div>
							</Col>
						</Row>
					}
				/>
			</div>
		</Layout>
	);
};

export default VolunteerFormPage;
